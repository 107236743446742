import React from 'react';
import Navbar from '../components/Navbar';
import EmailForm from '../components/EmailForm';
import './Home.css';

const Home = () => (
  <div className="home">
    <div className="hero-section">
      <div className="video-container">
        <video autoPlay loop muted className="background-video">
          <source src="/background4.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Navbar />
      <div className="hero-content">
        <h1>
          <span className="block">Hyper-Performant</span> 
          <span className="block">Financial Infrastructure</span>
        </h1>
        <EmailForm />
      </div>
    </div>
    <section className="mission-section">
      <div className="mission-content">
        <div className="mission-statement">
        <h2>Our Vision</h2>
          <p>
            We believe AI will have a vast impact on global finance. Optimal is committed to building modular, scalable, and secure financial infrastructure that will enable the creation of autonomous financial systems.
          </p>
        </div>
        <a href="https://calendly.com/arjunvnargolwala/30min" className="mission-link">
          Learn more<span className="arrow">➔</span>
        </a>
      </div>
    </section>
  </div>
);

export default Home;
