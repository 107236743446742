import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';


const Navbar = () => (
  <nav className="navbar">
    <div className="navbar-logo">
    <img src='./logo2.png' alt="Optimal Logo" className="navbar-logo-image" />
      Optimal
    </div>
    <div className="navbar-actions">
      <button
        className="schedule-meeting-btn"
        onClick={() => window.open('https://calendly.com/arjunvnargolwala/30min', '_blank')}
      >
        Meet Us
      </button>
    </div>
  </nav>
);

export default Navbar;
