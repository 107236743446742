import React, { useState } from 'react';
import './EmailForm.css';
const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      await fetch('https://nodemailer.optimalfinance.ai/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      setMessageVisible(true); // Show the message
      setTimeout(() => {
        setMessageVisible(false); // Hide the message after 3 seconds
      }, 3000);

      setEmail('');
    }
  };

  return (
    <div className="contact-us">
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={handleChange}
          placeholder="Email address"
          className="contact-input"
          required
        />
        <button type="submit" className="contact-button">Contact Us</button>
        {messageVisible && <div className="email-sent-message">Email sent.</div>}
      </form>
    </div>
  );
};

export default EmailForm;
